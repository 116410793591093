import React, { useEffect } from "react";
import axios from "axios";
import moment from "moment";

function App() {
  useEffect(() => {
    let date = moment(new Date()).format("YYYYMMDD");
    let url = `https://www.omie.es/es/file-download?parents%5B0%5D=marginalpdbc&filename=marginalpdbc_${date}.1`;
    axios({
      method: "GET",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((e) => {
        let arr = e.data.split(";");
        let price_arr = [];
        let price_count = 1;
        for (let i = 0; i < arr.length; i++) {
          if ((i + 1) % 6 === 0) {
            let object = {
              date: moment(new Date()).format("YYYY-MM-DD"),
              price: Number(arr[i]),
              hour: price_count++,
            };
            price_arr.push(object);
          }
        }
        // console.log(price_arr);
        return price_arr;
      })
      .catch((err) => console.log(err));
  }, []);
  return <div></div>;
}

export default App;
